import React from 'react'
import Aboutus from '../Components/Aboutus/Aboutus'

const About = () => {
  return (
    <div>
    <Aboutus />
    </div>
  )
}

export default About