import React from 'react'
import Explorepage from '../Components/Explorepage/Explorepage'
import Topinnovators from '../Components/Topinnovators/Topinnovators'

const Explore = () => {
  return (
    <div className='post-page'>
      <Explorepage />
      <Topinnovators />
    </div>
  )
}

export default Explore