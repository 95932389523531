import Benifit from "../Benifit/Benifit";
import "./Ideaform.css";

const Ideaform = () => {
  return (
    <div className="idea-form">
      <div className="list-form">
        <div className="idea-arrow">
          <img src="/assets/images/arrow.png" alt="" />
          <h3 className="fw-six text-black">Share your Idea</h3>
        </div>

        <div></div>

        <form action="">
          <input type="text" placeholder="Idea title" className="idea-title" />

          <input
            type="text"
            placeholder="Idea description"
            className="idea-description"
          />

          <input
            type="text"
            placeholder="Problem (Area of improvement)"
            className="idea-description"
          />

          <input
            type="text"
            placeholder="Proposed solution"
            className="idea-description"
          />

          <input
            type="text"
            placeholder="Benefit description"
            className="idea-description"
          />

          <div className="select-innovators idea-title bg-aash">
            <h6 className="fw-400">Innovators</h6>
            <div className="plus-circle">
              <div className="select-innovators-people">
                <div className="choose-innovator-people">
                  <img src="/assets/images/ali-2.png" alt="" />
                  <div className="choose-content">
                    <h5 className="fw-four text-black ">Ali Al ansari</h5>
                    <p className="fw-four text-gray ">
                      Sr Analyst - Digital Product Mgms
                    </p>
                  </div>
                </div>
                <div className="choose-innovator-people">
                  <img src="/assets/images/omar.png" alt="" />
                  <div className="choose-content">
                    <h5 className="fw-four text-black">Omar Mahmoud</h5>
                    <p className="fw-four text-gray">Sr. UI/UX Designer</p>
                  </div>
                  <img src="/assets/images/x.png" alt="" />
                </div>
              </div>
              <img src="/assets/images/plus.png" alt="" />
            </div>
          </div>

          <div>
            <Benifit />
          </div>
   <div>
   </div>

          <div className="attach">
            <p className="fw-six text-black">Attach Files</p>

            <div className="upload bg-aash">
              <img src="/assets/images/shape.png" alt="" />
              <h6 className="fw-seven text-green">
                Upload a files{" "}
                <span className="fw-seven text-gray">or drag and drop</span>
              </h6>
              <h6 className="fw-four text-gray">
                JPG, PNG, MP4, QuickTime (.mov files) up to 28.61 MB
              </h6>
            </div>
          </div>
          <div className="cancel-submit">
            <a href="#" className="cancel fw-six text-black">
              Cancel
            </a>
            <a href="#" className="submit fw-six text-white bg-green">
              Submit
            </a>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Ideaform;
