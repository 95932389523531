import React from "react";
import "./Header.css";

const Header = () => {
  return (
    <div className="header">
      <header>
        <div className="logos">
          <div className="afkari-1">
            <img src="/assets/images/afkari-1.png" alt="" />
          </div>
          <div className="line">
            <img src="/assets/images/afkari-logo-2.png" alt="" />
          </div>
          <div className="afkari-3">
            <img src="/assets/images/DEWA.png" alt="" />
          </div>
        </div>

        <div className="right-header ">
          <div className="searcch-bar bg-gray">
            <i className="fa-solid fa-magnifying-glass"></i>
          </div>
          <div className="access bg-gray">
            <img src="/assets/images/access.png" alt="" />
          </div>
          <div className="arabi bg-gray">
            <img src="/assets/images/arabi.png" alt="" />
          </div>
          <div className="ansari bg-gray">
            <div className="person">
              <img src="/assets/images/header-person.png" alt="" />
            </div>
            <h6>Ali Al ansari</h6>
            <div className="vector">
              <img src="/assets/images/vector.png" alt="" />
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
