import "./Howitworks.css";
import Work from "./Work";


function Howitworks() {
  return (
    <div>
      <h2 className="text-black fw-seven">How it works?</h2>
      <div>
        <Work
          no="1"
          name="AFKARI"
          description="Jumpstart innovation at the AFKARI Think Tank. Collaborate with colleagues, draw from industry trends, and explore unconventional approaches. Set the stage for groundbreaking ideas that will shape the future of our company."
          img ="./assets/images/work-line.png"
        />
          <Work
          no="2"
          name="Go Public"
          description="Announce your groundbreaking ideas on the AFKARI platform, where innovation takes center stage. Share your vision for enhancing the company's success and contributing to the spirit of the UAE's Year of Innovation."
          img ="./assets/images/work-line.png"
        /> 
       <Work
        no="3"
        name="Identification"
        description="Identify the key areas in which your ideas can make a significant impact. Clearly define the challenges or opportunities you aim to address, aligning your innovation with the company's goals."
        img ="./assets/images/work-line.png"
      />
        <Work
          no="4"
          name="Initial Evaluation"
          description="Present a concise overview of your idea, highlighting its feasibility and potential benefits. Get initial feedback from peers and mentors, refining your concept for maximum impact."
          img ="./assets/images/work-line.png"
        />
          <Work
          no="5"
          name="Divisional Evaluation"
          description="Engage with departmental stakeholders and subject matter experts to conduct a comprehensive evaluation. Fine-tune your proposal based on specific departmental needs, ensuring alignment with broader organizational objectives."
          img ="./assets/images/work-line.png"
        />
         <Work
          no="6"
          name="Your Award"
          description="Stand out for your innovative contributions! Receive recognition and awards for your outstanding ideas, fostering a culture of appreciation and motivation among colleagues."
          img ="./assets/images/work-line.png"
        />
         <Work
          no="7"
          name="Implementation"
          description="Take your idea from concept to reality. Collaborate with relevant teams to implement your innovation, turning aspirations into tangible solutions that elevate the company's performance."
          img ="./assets/images/work-line.png"
        />
          <Work
          no="8"
          name="More Possibilities"
          description="Your innovation journey doesn't end here. Explore additional opportunities and possibilities for improvement. AFKARI is not just a platform; it's a catalyst for continuous innovation, encouraging you to push boundaries and discover new ways to enhance the company's success."
        />
      </div>
    </div>
  );
}

export default Howitworks;