import Person from "../Person/Person";
import Sharebtn from "../Sharebtn/Sharebtn";
import "./Transmissioncontrol.css";


const Transmissioncontrol = () => {
  const control = [
    {
      description : "Transmission Control Room shift engineers hand over various activities during the end of each shift to the engineers in the next shift. These hand over activities are currently managed and tracked ",
      see : "see more..."
      
    },
  ];
  return (
    <div>
    <div className="people-data">
      <Person
        profileimg="/assets/images/ahmad-al.png"
        name="Ahmad Al Saleh"
        role="Sr Specialist - Investments"
        vector="/assets/images/world-vector.png"
        date="Nov 24"
        id="ID: 2023-14121"
        eclipse="/assets/images/Ellipse.png"
      />

      {control.map((team) => (
        <div className="solar-permits"> 
         <h4 className="fw-four text-black line-h-two">{team.description}<span className="fw-four text-gray">{team.see}</span>
        </h4>
        </div>

      ))}
      <Sharebtn />

      </div>
    </div>
  );
};

export default Transmissioncontrol;
