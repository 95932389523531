import React from 'react'
import Help from '../Components/Helpfaq/Help'

const Helpfaq = () => {
  return (
    <div>
        <Help />
    </div>
  )
}

export default Helpfaq