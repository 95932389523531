import React from 'react';
import "./Drafts.css";


const Drafts = () => {
  return (
    <div className='drafts-page'>
  <h3 className="fw-six text-black">Drafts (2)</h3>

  <div className="drafts bg-gray">
    <div className="draft-content">
        <h5 className="fw-five text-black mg-seven">Letraset sheets containing Lorem Ipsum passages, and more recently with ...</h5>
        <h5 className="fw-four text-gray">10 days until expiration</h5>
    </div>
    <img src="./assets/images/news.png" alt="" />
  </div>

  <div className="drafts bg-gray">
    <div className="draft-content">
        <h5 className="fw-five text-black mg-seven">Letraset sheets containing Lorem Ipsum passages, and more recently with ...</h5>
        <h5 className="fw-four text-gray">25 days until expiration</h5>
    </div>
    <img src="./assets/images/similar-idea-1.png" alt="" />
  </div>

    </div>
  )
}

export default Drafts