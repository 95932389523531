import "./Similarinnovators.css";

const Similarinnovator = () => {
  return (
    <div className="similar-innovator">
      
      <div className="related-innovator  bg-aash">
        <h4 className="fw-seven text-black">Innovators</h4>
        <div className="related-innovator-people">
            <img src="/assets/images/ali.png" alt="" />
            <div className="related-content">
                <h5 className="fw-five text-black line-h-two">Ali Al ansari</h5>
                <h5 className="fw-four text-gray line-h-two">Sr Analyst - Digital Product Mgms</h5>
            </div>
        </div>
        <div className="related-innovator-people">
            <img src="/assets/images/shady.png" alt="" />
            <div className="related-content">
                <h5 className="fw-five text-black line-h-two">Shady Abdullah</h5>
                <h5 className="fw-four text-gray line-h-two">Sr. UI/UX Designer</h5>
            </div>
        </div>
      </div>

<div className="related-innovator bg-aash">
<h4 className="fw-seven text-black">Related Topics</h4>
<button>Creativity</button>
<button>Innovation new plan & ideas</button>
<button>Smart UAE</button>
<button>Strategy </button>
<button>Digitalization</button>
<button>Innovation</button>
<button>Real time operation</button>

</div>


      <div className="news-all bg-aash">
        <div className="news">
          <h4 className="fw-seven text-black">Similar Ideas</h4>
          <div className="nov-news">
            <div className="nov-news-content">
              <h5 className="fw-five text-black">
              Letraset sheets containing Lorem Ipsum passages, and more recently with ...
              </h5>
            </div>
            <img src="/assets/images/similar-idea-1.png" alt="" />
          </div>

          <div className="nov-news">
            <div className="nov-news-content">
              <h5 className="fw-five text-black">
                Letraset sheets containing Lorem Ipsum passages, and more
                recently with ...
              </h5>
            </div>
            <img src="/assets/images/similar-idea-2.png" alt="" />
          </div>
          <p className="text-green fw-five"> See more</p>
        </div>
      </div>

    </div>
  )
}

export default Similarinnovator