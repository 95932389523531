import React from 'react'
import Postlist from '../Components/Postlist/Postlist'
import Innovators from '../Components/Innovators/Innovators'

const Home = () => {
  return (

       <div className='post-page'>
       <Postlist />
       <Innovators />
       
    </div>

  )
}

export default Home