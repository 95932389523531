


function Work(props) {
    return (
    <div>
      <div className="how-it-work">
        <div className="how-it-count text-black">
          <h3 className="bg-gray">{props.no}</h3>
          <img src={props.img} alt="" />

        </div>

        <div className="how-it-content">
              <h4>{props.name}</h4>
              <h6>{props.description}</h6>
        </div>
      </div>
        
      </div>
    );
}

export default  Work