import React from 'react'
import Ideaform from '../Components/Ideaform/Ideaform'
import Drafts from '../Components/Drafts/Drafts'

const Shareformtwo = () => {
  return (
    <div className='post-page'>
  <Ideaform />
  <Drafts />
    </div>
  )
}

export default Shareformtwo