import { Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './Pages/Home';
import Nav from './Components/Nav/Nav';
import Idea from './Pages/Idea';
import Header from './Components/Header/Header';
import Shareform from './Pages/Shareform';
import Shareformtwo from './Pages/Shareformtwo';
import About from './Pages/About';
import Helpfaq from './Pages/Helpfaq';
import Explore from './Pages/Explore';


function App() {
    return (
        <main>
            <Header />
            <div className='container'>

              <div className='main-page'>

                <div className='navbar'>
                    <Nav />
                </div>

                <div className='routing-page'>
                    <Routes>
                        <Route exact path="/" Component={Home} />
                        <Route path="/idea" Component={Idea} />
                        <Route path="/share-idea" Component={Shareform} />
                        <Route path="/share-idea-2" Component={Shareformtwo} />
                        <Route path="/about" Component={About} />
                        <Route path="/helpfaq" Component={Helpfaq} />
                        <Route path="/explore" Component={Explore} />





                    </Routes>
                </div>

              </div>
            </div>


        </main>
    );
};



export default App;
