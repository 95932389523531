import { Link } from "react-router-dom";
import "./Innovators.css";

const Innovators = () => {
  return (
    <div className="innovators-list ">

      <div className="knowledge-corner">
        <h4 className="fw-seven text-black">New Name for the “Knowledge Corner”</h4>
        <div className="knowledge-corner-content">
          <h4 className="fw-four text-gray">
            Join our innovative naming campaign and contribute your valuable
            suggestions!
          </h4>
          <img src="/assets/images/knowledge-corner.png" alt="" />
        </div>
        <Link to ='/' className="share-idea-btn-secondary fw-five text-white bg-green">Share your solution</Link>
      </div>

      <div className="news-all bg-aash">
        <div className="news">
          <h4 className="fw-seven text-black">News</h4>
          <div className="nov-news">
            <div className="nov-news-content">
              <h5 className="fw-five text-gray">23 Nov</h5>
              <h5 className="fw-five text-black">
                Letraset sheets containing Lorem Ipsum passages, and more
                recently with ...
              </h5>
            </div>
            <img src="/assets/images/news.png" alt="" />
          </div>

          <div className="nov-news">
            <div className="nov-news-content">
              <h5 className="fw-five text-gray">23 Nov</h5>
              <h5 className="fw-five text-black">
                Letraset sheets containing Lorem Ipsum passages, and more
                recently with ...
              </h5>
            </div>
            <img src="/assets/images/latest-news.png" alt="" />
          </div>
          <p className="text-green fw-five"> See more innovators</p>
        </div>
      </div>

      <div className="top-innovators-all bg-aash">
        <div className="top-innovators">
          <h4 className="fw-seven text-black">Top innovators</h4>
          <div className="innovators-people">
            <div className="steve-maroron">
              <img src="/assets/images/steeve-marron.png" alt="" />
              <div className="steve-maroron-content">
                <h5 className="fw-five text-black">Steve Marron</h5>
                <h5 className="fw-four text-gray">Specialist- Enterprise Architecture</h5>
              </div>
            </div>
            <div className="follow">
              <button>Follow</button>
            </div>
          </div>

          <div className="innovators-people">
            <div className="steve-maroron">
              <img src="/assets/images/ryan-ros.png" alt="" />
              <div className="steve-maroron-content">
                <h5 className="fw-five text-black">Ryan Roslansky</h5>
                <h5 className="fw-four text-gray">Sr Specialist - Digital Engg</h5>
              </div>
            </div>
            <div className="follow fw-five">
              <button>Follow</button>
            </div>
          </div>

          <div className="innovators-people">
            <div className="steve-maroron">
              <img src="/assets/images/dylan.png" alt="" />
              <div className="steve-maroron-content">
                <h5 className="fw-five text-black">Dylan Field</h5>
                <h5 className="fw-four text-gray">Asst Mgr - Contracts</h5>
              </div>
            </div>
            <div className="follow fw-five">
              <button>Follow</button>
            </div>
          </div>
        </div>
        <p className="text-green fw-five"> See more innovators</p>

      </div>

      <div className="innovation-enablers bg-aash">
       <h4 className="fw-seven text-black">Innovation Enablers</h4>

    <div className="future-trends">
      <div className="future-img">
      <img src="/assets/images/future-trends.png" alt="" />
      </div>
      <h5 className="fw-four text-black">Future Trends</h5>
    </div>

    <div className="future-trends">
      <div className="future-img">
      <img src="/assets/images/linked-in.png" alt="" />
      </div>
      <h5 className="fw-four  text-black">Linkedin</h5>
    </div>

    <div className="future-trends">
      <div className="future-img">
      <img src="/assets/images/bank.png" alt="" />
      </div>
      <h5 className="fw-four  text-black">Opportunities Bank</h5>
    </div>

   </div>

    </div>
  );
};

export default Innovators;
