import React from "react";
import Person from "../Person/Person";
import Sharebtn from "../Sharebtn/Sharebtn";
import "./Application.css";

const Application = () => {
  const connection = [
    {
      description:
        "Beat the traffic with SkyPod—your futuristic ride above the city. 🚀",
      img1: "/assets/images/city.png",
    },
  ];
  return (
    <div>
      <div className="people-data">
        <div className="implement">
          <Person
            profileimg="/assets/images/khaled.png"
            name="Khaled Meshal"
            role="UI/UX Designer"
            vector="/assets/images/world-vector.png"
            date="Nov 26"
            id="ID: 2023-14406"
            eclipse="/assets/images/Ellipse.png"
          />
         
          <div className="implement-share">
          <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 14 14"
              fill="none"
            >
              <path
                d="M12.5766 6.26483L11.7833 5.34316C11.6316 5.16816 11.5091 4.8415 11.5091 4.60816V3.6165C11.5091 2.99816 11.0016 2.49066 10.3833 2.49066H9.39161C9.16411 2.49066 8.83161 2.36816 8.65661 2.2165L7.73494 1.42316C7.33244 1.079 6.67327 1.079 6.26494 1.42316L5.34911 2.22233C5.17411 2.36816 4.84161 2.49066 4.61411 2.49066H3.60494C2.98661 2.49066 2.47911 2.99816 2.47911 3.6165V4.614C2.47911 4.8415 2.35661 5.16816 2.21078 5.34316L1.42328 6.27066C1.08494 6.67316 1.08494 7.3265 1.42328 7.729L2.21078 8.6565C2.35661 8.8315 2.47911 9.15816 2.47911 9.38566V10.3832C2.47911 11.0015 2.98661 11.509 3.60494 11.509H4.61411C4.84161 11.509 5.17411 11.6315 5.34911 11.7832L6.27077 12.5765C6.67327 12.9207 7.33244 12.9207 7.74078 12.5765L8.66244 11.7832C8.83744 11.6315 9.16411 11.509 9.39744 11.509H10.3891C11.0074 11.509 11.5149 11.0015 11.5149 10.3832V9.3915C11.5149 9.164 11.6374 8.8315 11.7891 8.6565L12.5824 7.73483C12.9208 7.33233 12.9208 6.66733 12.5766 6.26483ZM9.72994 6.99983L9.04744 9.0765C8.95994 9.42066 8.59244 9.70066 8.21911 9.70066H7.13994C6.95327 9.70066 6.69078 9.6365 6.57411 9.51983L5.71661 8.849C5.69911 9.22233 5.52994 9.37983 5.11578 9.37983H4.83577C4.40411 9.37983 4.22911 9.21066 4.22911 8.80233V6.014C4.22911 5.60566 4.40411 5.4365 4.83577 5.4365H5.12161C5.55327 5.4365 5.72827 5.60566 5.72827 6.014V6.224L6.85994 4.544C6.97661 4.36316 7.27411 4.23483 7.52494 4.334C7.79911 4.42733 7.97411 4.73066 7.91577 4.999L7.77577 5.909C7.76411 5.99066 7.78161 6.0665 7.83411 6.12483C7.88077 6.17733 7.95078 6.21233 8.02661 6.21233H9.16411C9.38577 6.21233 9.57244 6.29983 9.68328 6.45733C9.78244 6.609 9.79994 6.8015 9.72994 6.99983Z"
                fill="#007560"
              />
            </svg>
            <h6 className="fw-four"> Implemented</h6>
          </div>
        </div>
        {connection.map((application) => (
          <div className="solar-permits">
            <h4 className="fw-four text-black">{application.description}</h4>
            <img src={application.img1} alt="" />
          </div>
        ))}
        <Sharebtn />
      </div>
    </div>
  );
};

export default Application;
