import React from 'react'
import Ideaform from '../Components/Ideaform/Ideaform'

const Shareform = () => {
  return (
    <div>
  <Ideaform />
    </div>
  )
}

export default Shareform