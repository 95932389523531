import React from 'react'
import Digital from '../Components/Digital/Digital'
import Similarinnovator from '../Components/Similarinnovators/Similarinnovators'

const Idea = () => {
  return (
    <div className='post-page'> 
   <Digital />
   <Similarinnovator />
    </div>
  )
}

export default Idea 