import React from "react";
import "./Help.css";
import { useState } from "react";

function Help() {
  const [selected, setSelected] = useState(null);

  const toggle = (i) => {
    if (selected === i) {
      return setSelected(null);
    }
    setSelected(i);
  };
  return (
    <div className="helpfaq">
      <h2 className="text-black fw-seven">Help & Frequently Asked Questions</h2>
      <div className="help-accordion">
        <div className="accordion">
          {data.map((item, i) => (
            <div className="frequently-question">
              <div className="question text-black" onClick={() => toggle(i)}>
                <h4 className="fw-five">{item.question}</h4>
                <span
                  className={selected === i ? "down-arrow" : "up-arrow"}
                ></span>
              </div>
              <div className={selected === i ? "show" : "answer"}>
                <h4 className=" text-gray fw-four">{item.answer}</h4>
              </div>
            </div>
          ))}
        </div>
      </div>
       
       <div className="contact-us">
      <h3 className="fw-six">Contact Us</h3>
      <h4 className="text-gray fw-four">If your query isn't addressed in the FAQs, feel free to contact us at the following email:</h4>
      <div className="sms">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17 3.5H7C4 3.5 2 5 2 8.5V15.5C2 19 4 20.5 7 20.5H17C20 20.5 22 19 22 15.5V8.5C22 5 20 3.5 17 3.5ZM17.47 9.59L14.34 12.09C13.68 12.62 12.84 12.88 12 12.88C11.16 12.88 10.31 12.62 9.66 12.09L6.53 9.59C6.21 9.33 6.16 8.85 6.41 8.53C6.67 8.21 7.14 8.15 7.46 8.41L10.59 10.91C11.35 11.52 12.64 11.52 13.4 10.91L16.53 8.41C16.85 8.15 17.33 8.2 17.58 8.53C17.84 8.85 17.79 9.33 17.47 9.59Z" />
</svg>
        <p className="fw-four">Innovatuon@dewa.gov.ae</p>
      </div>
      </div>
    </div>
  );
}


const data = [
  {
    question: "What is an idea?",
    answer:
      "Any useful or new improvement to processes, operations, services, products, markets, and management practices.",
  },
  {
    question: "How to update your user profile?",
    answer:
      "Any useful or new improvement to processes, operations, services, products, markets, and management practices.",
  },
  {
    question: "How can I submit an idea?",
    answer:
      "Any useful or new improvement to processes, operations, services, products, markets, and management practices.",
  },
  {
    question: "Can we send ideas as a team?",
    answer:
      "Any useful or new improvement to processes, operations, services, products, markets, and management practices.",
  },
  {
    question: "What is the difference between the ideas categories?",
    answer:
      "Any useful or new improvement to processes, operations, services, products, markets, and management practices.",
  },
  {
    question: "What are the ideas eligible to participate according to Afkari?",
    answer:
      "Any useful or new improvement to processes, operations, services, products, markets, and management practices.",
  },
  {
    question: "Don’t end ideas that are not eligible to participate?",
    answer:
      "Any useful or new improvement to processes, operations, services, products, markets, and management practices.",
  },
  {
    question: "How can I track my ideas?",
    answer:
      "Any useful or new improvement to processes, operations, services, products, markets, and management practices.",
  },
  {
    question: "How to be recognized?",
    answer:
      "Any useful or new improvement to processes, operations, services, products, markets, and management practices.",
  },
  {
    question: "What is Afkari rewards scheme?",
    answer:
      "Any useful or new improvement to processes, operations, services, products, markets, and management practices.",
  },
];

export default Help;
