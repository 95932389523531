import "./Postlist.css";

const Postlist = () => {
  return (
    <div className="postlist ">
      <div className="share-idea">
        <div className="idea-box">
          <img src="/assets/images/share-idea-img.png" alt="" />
          <p className="text-gray fw-four">
            Share Your Idea and Inspire Change!
          </p>
        </div>
        <div className="edit">
          <img src="/assets/images/Edit-img.png" />
        </div>
      </div>

      <div className="sort-recent">
        <div className="sort"></div>
        <div className="recent">
          <h6 className="text-gray fw-five">
            Sort by: <span className="text-black "> Recent</span>
          </h6>
          <div className="vector">
            <img src="/assets/images/vector.png" alt="" />
          </div>
        </div>
      </div>

      <div className="connection-application bg-aash">
        <div className="nouran-al">
          <img src="/assets/images/nouran.png" alt="" />
          <div className="nouran-content">
            <h5 className="fw-five text-black">Nouran Al mheiri</h5>
            <h5 className="text-gray fw-four">Sr Specialist - Investments</h5>
            <div className="world-vector">
              <img src="/assets/images/world-vector.png" alt="" />
              <h5 className="text-gray fw-four">Nov 21</h5>
            </div>
          </div>
        </div>
        <div className="solar-permits">
          <h4 className="fw-four text-black">
            New combined Solar Permits & Connection applications.
          </h4>
          <img src="/assets/images/solar-img.png" alt="" />
        </div>

        <div className="vote-box text-gray">
          <h6 className="fw-four">144 Vote</h6>
          <h6 className="fw-four">201 Comments</h6>
          <h6 className="fw-four">30 Shares</h6>
        </div>

        <div className="vote-shares">
          <div className="like-btn">
            <div className="vote-btn">
              <img src="/assets/images/like-vector.png" alt="" />
              <h6 className="text-black fw-four">Vote</h6>
            </div>
            <div className="vote-btn">
              <img src="/assets/images/comment-vector.png" alt="" />
              <h6 className="text-black fw-four">Comment</h6>
            </div>
            <div className="vote-btn">
              <img src="/assets/images/share-vector.png" alt="" />
              <h6 className="text-black fw-four">Share</h6>
            </div>
          </div>

          <div className="bookmark-vector">
            <img src="/assets/images/Bookmark-vector.png" alt="" />
          </div>
        </div>
      </div>

      <div className="connection-application">
        <div className="nouran-al">
          <img src="/assets/images/ahmad-al.png" alt="" />
          <div className="nouran-content">
            <h5 className="fw-five text-black">Ahmad Al Saleh</h5>
            <h5 className="text-gray fw-four">Sr Specialist - Investments</h5>
            <div className="world-vector">
              <img src="/assets/images/world-vector.png" alt="" />
              <h5 className="text-gray fw-four">Nov 21</h5>
            </div>
          </div>
        </div>
        <h4 className="fw-four text-black line-h-two">
          Transmission Control Room shift engineers hand over various activities
          during the end of each shift to the engineers in the next shift. These
          hand over activities are currently managed and tracked{" "}
          <span className="fw-four text-gray">see more...</span>{" "}
        </h4>

        <div className="vote-box text-gray fw-four">
          <h6 className="fw-four">144 Vote</h6>
          <h6 className="fw-four">201 Comments</h6>
          <h6 className="fw-four">30 Shares</h6>
        </div>

        <div className="vote-shares">
          <div className="like-btn">
            <div className="vote-btn">
              <img src="/assets/images/like-vector.png" alt="" />
              <h6 className="text-black fw-four">Vote</h6>
            </div>
            <div className="vote-btn">
              <img src="/assets/images/comment-vector.png" alt="" />
              <h6 className="text-black fw-four">Comment</h6>
            </div>
            <div className="vote-btn">
              <img src="/assets/images/share-vector.png" alt="" />
              <h6 className="text-black fw-four">Share</h6>
            </div>
          </div>

          <div className="bookmark-vector">
            <img src="/assets/images/Bookmark-vector.png" alt="" />
          </div>
        </div>
      </div>

      <div className="connection-application ">
        <div className="nouran-al">
          <img src="/assets/images/nadia-ramy.png" alt="" />
          <div className="nouran-content">
            <h5 className="fw-five text-black">Nadia Ramy</h5>
            <h5 className="text-gray fw-four">Sr Specialist - Investments</h5>
            <div className="world-vector">
              <img src="/assets/images/world-vector.png" alt="" />
              <h5 className="text-gray fw-four">Nov 21</h5>
            </div>
          </div>
        </div>
        <h4 className="fw-four text-black">
          New combined Solar Permits & Connection applications.
        </h4>

        <div className="robo-solar">
          <img src="/assets/images/solar-white-robo.png" alt="" />
          <img src="/assets/images/solar-black-robo.png" alt="" />
        </div>

        <div className="vote-box text-gray fw-four">
          <h6 className="fw-four">144 Vote</h6>
          <h6 className="fw-four">201 Comments</h6>
          <h6 className="fw-four">30 Shares</h6>
        </div>

        <div className="vote-shares">
          <div className="like-btn">
            <div className="vote-btn">
              <img src="/assets/images/like-vector.png" alt="" />
              <h6 className="text-black fw-four">Vote</h6>
            </div>
            <div className="vote-btn">
              <img src="/assets/images/comment-vector.png" alt="" />
              <h6 className="text-black fw-four">Comment</h6>
            </div>
            <div className="vote-btn">
              <img src="/assets/images/share-vector.png" alt="" />
              <h6 className="text-black fw-four">Share</h6>
            </div>
          </div>

          <div className="bookmark-vector">
            <img src="/assets/images/Bookmark-vector.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Postlist;
