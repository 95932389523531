import React from "react";
import "./Person.css";



function Person(Props) {
    return (
          <div className="people-description">
          <img src={Props.profileimg} className="nouran" />
          <div className="people-content">
            <h5 className="fw-five text-black">{Props.name}</h5>
            <h5 className="text-gray fw-four">{Props.role}</h5>
            <div className="under-discus">
              <img src={Props.vector} className="time" />
              <h5 className="fw-four text-gray">{Props.date}</h5>
              <img src={Props.eclipse} className="ellipse" />
              <h5 className="fw-four text-gray">{Props.id}</h5>
            </div>
          </div>
        </div>


        
    );
}

export default  Person