import React from "react";
import "./Aboutus.css";
import Howitworks from "../../Howitworks/Howitworks";

const Aboutus = () => {
  return (
    <div className="about">
      <h2 className="text-black fw-seven">About Afkari</h2>

      <div className="internal-system">
        <p className="fw-four">
          Internal system for all DEWA employees including:<br></br>
          Top Managment, Middle Management, Technical, Administrative and
          workers.<br></br>
          AFKARI is accessible through website, Mobile app, accross all DEWA
          branches.
        </p>
      </div>

      <div className="ceo-msg">
        <img src="./assets/images/ceo.png" alt="" />
        <div className="ceo-content">
          <h2 className="text-black ">Our CEO's Message</h2>
          {/*   <img src="./assets/images/quotation.png" alt="" /> */}
          <p>
            The announcement by the UAE Cabinet to mark 2015 as the Year of
            Innovation was a new step in the UAE's development. It is my
            pleasure to welcome you to this portal which supports DEWA's
            strategy to encourage creativity and innovation. It supports the
            vision of HH Sheikh Mohammed bin Rashid Al Maktoum, Vice President
            and Prime Minister of the UAE and Ruler of Dubai, to make innovation
            a key element in daily government work, and an organisational
            culture.<br></br> It also complements the National Innovation
            Strategy, launched by His Highness to make the UAE one of the most
            innovative countries in the world.
          </p>
        </div>
      </div>

      <div className="dewa-launch">
        <p>
          DEWA has launched this portal as part of its strategy to provide an
          environment that stimulates the skills and creativity of its staff.
          <br></br>
          We believe in the role staff ideas and suggestions play in improving
          the working environment and developing the services provided to
          customers and partners.
        </p>
        <p>
          DEWA adopts innovation as one of the established methodologies in the
          organisational decision-making process to achieve the Dubai Plan 2021,
          to make Dubai a city of happy, creative, and empowered people. I hope
          this portal will help to improve the work environment, enhance
          communication among all staff, and be the place for studying and
          discussing staff suggestions and ideas with all divisions and
          departments.
        </p>
        <p>
          I invite you all to share your ideas and suggestions, which will be
          studied and evaluated no matter how small or insignificant they might
          seem. We will implement the appropriate suggestions that support our
          vision to become a sustainable world class utility.
        </p>
      </div>

      <div className="thankyou">
        <div className="thankyou-content">
          <p>Thank you! </p>
          <h4>Saeed Mohammed Al Tayer . MD&CEO of DEWA </h4>
        </div>
        <img src="./assets/images/quotation.png" alt="" />
      </div>

      <Howitworks />
    </div>
  );
};

export default Aboutus;
