import React from 'react'
import "./Benifit.css";



function Benifit() {

    const options = [
		{label : "Benifits" , value : 1},
		{label : "Benifits" , value : 2},
		{label : "Benifits" , value : 3},
		]
		return (
			<div>
				<select>
                     {options.map(option => (
						<option value={option.value}>{option.label}</option>
					 ))}
				</select>
			</div>
		);
	 

}



export default Benifit