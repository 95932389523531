import Person from "../Person/Person";
import Sharebtn from "../Sharebtn/Sharebtn";
import "./Solarpermits.css";


const Solarpermits = () => {
  const application = [
    {
      description : "New combined Solar Permits & Connection applications.",
      img1 : "/assets/images/solar-small.png",
      img2: "/assets/images/solar-blue.png"
    },
  ];
  return (
    <div>
    <div className="people-data">
      <Person
        profileimg="/assets/images/ali.png"
        name="Ali Al ansari"
        role="Sr Specialist - Investments"
        vector="/assets/images/world-vector.png"
        date="Nov 24"
        id="ID: 2023-14406"
        eclipse="/assets/images/Ellipse.png"
      />

      {application.map((connection) => (
        <div>
          <h4 className="fw-four text-black">{connection. description}</h4>

          <div className="robo-solar">
            <img src={connection.img1} alt="" />
            <img src={connection.img2} alt="" />
          </div>
        </div>

      ))}
      <Sharebtn />

      </div>
    </div>
  );
};

export default Solarpermits;
