import "./Digital.css";

const Digital = () => {
  return (
    <div className="digital">
      <div className="idea-arrow">
        <img src="/assets/images/arrow.png" alt="" />
        <h3 className="fw-four text-black">Idea</h3>
      </div>

      <div className="digitalization">
        <h4 className="fw-seven text-black ">
          Digitalization of Real Time Operation tools - Handing Over
        </h4>
        <h4 className="fw-four text-black line-h-two inter-font">
          Digitalization of Real Time handing over procedures and file aiming to
          automate and ease the process and to make it more convenient to real
          time operators.
        </h4>
      </div>

      <div className="under-discus">
        <img src="/assets/images/world-vector.png" className="time" alt="" />
        <h5 className="fw-four text-gray">Nov 21</h5>
        <img src="/assets/images/Ellipse.png" className="ellipse" alt="" />
        <img src="/assets/images/time.png" className="time" alt="" />
        <h5 className="fw-four text-gray">Under discussion & voting</h5>
        <img src="/assets/images/Ellipse.png" className="ellipse" alt="" />
        <h5 className="fw-four text-gray">ID: 2023-14406</h5>
      </div>
      <div className="comment-vote">
        <div className="comment-share text-gray">
          <h6 className="fw-four">144 Vote</h6>
          <h6 className="fw-four">201 Comments</h6>
          <h6 className="fw-four">30 Shares</h6>
        </div>
        <div className="share-bookmar">
          <img src="/assets/images/share-vector.png" alt="" />
          <img src="/assets/images/Bookmark-vector.png" alt="" />
        </div>
      </div>

      <div className="yellow-robo">
        <img src="/assets/images/yellow-robo.png" alt="" />
      </div>

      <div className="problem-solution">
        <h4 className="fw-seven line-h-six inter-font">Problem</h4>
        <h4 className="fw-four line-h-six inter-font">
          Transmission Control Room shift engineers hand over various activities
          during the end of each shift to the engineers in the next shift. These
          hand over activities are currently managed and tracked through excel
          sheets, which are prone to mishandling and errors in data editing.
        </h4>

        <h4 className="fw-seven line-h-six inter-font">Solution</h4>
        <h4 className="fw-four line-h-six inter-font">
          Unify the data entry in all TCC tools (TOD switching, Handover, TCC
          logs, shift schedule and routine activity file). Therefore, as an
          improvement to the process RTO has suggested to automate the hand over
          activities by integrating with the TCC Logs & TOD Switching
          applications as 1st phase to pull-in the required data and take
          necessary actions through a web application by eliminating the manual
          excel sheets
        </h4>

        <h4 className="fw-seven line-h-six inter-font">Benefit</h4>
        <h4 className="fw-four line-h-six inter-font">
          Improve Dewa's Operations
        </h4>

        <h4 className="fw-seven line-h-six inter-font">Benefit Description</h4>
        <h4 className="fw-four line-h-six inter-font">
          Unify the data entry in all TCC tools and to automate and ease the
          process and to make it more convenient to real time operators
        </h4>

        <h4 className="fw-seven line-h-six inter-font">Category</h4>
        <h4 className="fw-four line-h-six inter-font">Fast Track</h4>
      </div>

      <h4 className="fw-seven line-h-six inter-font">Comments (2)</h4>

      <div className="share-idea share-thoughts">
        <div className="idea-box">
          <img src="/assets/images/share-idea-img.png" alt="" />
          <p className="text-gray fw-four">Share your thoughts</p>
        </div>
      </div>

      <div className="bjarni">
        <div className="nakamura">
          <img src="/assets/images/bijarni.png" alt="" />
          <div className="nakamura-content">
            <h5 className="fw-five text-black">Bjarni Nakamura</h5>
            <h5 className="text-gray fw-four">Sr Exec - Experience Mgmt</h5>
          </div>
        </div>
        <div className="nakamura">
          <h6 className="fw-four text-gray">2h</h6>
          <img src="/assets/images/dots-three.png" alt="" />
        </div>
      </div>

      <div className="lorem-content bg-aash">
        <h4 className="fw-four line-h-six">
          Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
          commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus
          et magnis dis parturient montes, nascetur ridiculus mus.{" "}
        </h4>
      </div>

      <div className="replies">
        <div className="like-reply">
          <div className="vote-btn">
            <img src="/assets/images/like-vector.png" alt="" />
            <h6 className="text-black fw-four">8</h6>
          </div>
          <div className="vote-btn">
            <img src="/assets/images/comment-vector.png" alt="" />
            <h6 className="text-black fw-four">4 replies</h6>
          </div>
        </div>
        <div className="reply">
          <h6 className="fw-four text-gray">Reply</h6>
        </div>
      </div>
  
      <div className="bjarni">
        <div className="nakamura">
          <img src="/assets/images/sundar.png" alt="" />
          <div className="nakamura-content">
            <h5 className="fw-five text-black">Sundar Rajan</h5>
            <h5 className="text-gray fw-four">Development manager</h5>
          </div>
        </div>
        <div className="nakamura">
          <h6 className="fw-four text-gray">4h</h6>
          <img src="/assets/images/dots-three.png" alt="" />
        </div>
      </div>

      <div className="lorem-content bg-aash">
        <h4 className="fw-four line-h-six">
          Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
          commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus
          et magnis dis parturient montes, nascetur ridiculus mus.{" "}
        </h4>
      </div>

      <div className="replies">
        <div className="like-reply">
          <div className="vote-btn">
            <img src="/assets/images/like-vector.png" alt="" />
            <h6 className="text-black fw-four">19</h6>
          </div>
          <div className="vote-btn">
            <img src="/assets/images/comment-vector.png" alt="" />
            <h6 className="text-black fw-four">2 replies</h6>
          </div>
        </div>
        <div className="reply">
          <h6 className="fw-four text-gray">Reply</h6>
        </div>
      </div>
      

    </div>
  );
};

export default Digital;
