import React from 'react';
import "./Explorepage.css";
import Solarpermits from '../Solarpermits/Solarpermits';
import Application from '../Application/Application';
import Transmissioncontrol from '../Transmissioncontrol/Transmissioncontrol';



const Explorepage = () => {
  const recent = [
    {
    sort : " Sort by:",
    recent : "Recent"
      
    },
  ];
  return (
    <div className='explorepage'>
       {recent.map((sort) => (
        <div className="sort-recent">
        <div className="sort"></div>
        <div className="recent">
          <h6 className="text-gray fw-five">{sort.sort}
            <span className="text-black "> {sort.recent}</span>
          </h6>
          <div className="vector">
            <img src="/assets/images/vector.png" alt="" />
          </div>
        </div>
      </div>

      ))}
      <Application />
      <Transmissioncontrol />
      <Solarpermits />
    </div>
  )
}

export default Explorepage