import React from 'react'

const Topinnovators = () => {
  return (
    <div className='innovators-list'>
          <div className="top-innovators-all bg-aash">
        <div className="top-innovators">
          <h4 className="fw-seven text-black">Suggested Innovators</h4>
          <div className="innovators-people">
            <div className="steve-maroron">
              <img src="/assets/images/steeve-marron.png" alt="" />
              <div className="steve-maroron-content">
                <h5 className="fw-five text-black">Steve Marron</h5>
                <h5 className="fw-four text-gray">Specialist- Enterprise Architecture</h5>
              </div>
            </div>
            <div className="follow">
              <button>Follow</button>
            </div>
          </div>

          <div className="innovators-people">
            <div className="steve-maroron">
              <img src="/assets/images/ryan-ros.png" alt="" />
              <div className="steve-maroron-content">
                <h5 className="fw-five text-black">Ryan Roslansky</h5>
                <h5 className="fw-four text-gray">Sr Specialist - Digital Engg</h5>
              </div>
            </div>
            <div className="follow fw-five">
              <button>Follow</button>
            </div>
          </div>

          <div className="innovators-people">
            <div className="steve-maroron">
              <img src="/assets/images/dylan.png" alt="" />
              <div className="steve-maroron-content">
                <h5 className="fw-five text-black">Dylan Field</h5>
                <h5 className="fw-four text-gray">Asst Mgr - Contracts</h5>
              </div>
            </div>
            <div className="follow fw-five">
              <button>Follow</button>
            </div>
          </div>
        </div>
        <p className="text-green fw-five">View Community</p>

      </div>
    </div>
  )
}

export default Topinnovators